<template>
  <modal
    id="modalEditarPeriodoFinanceiro"
    :exibir="exibir"
    :titulo="$t('GERAL.EDITAR')"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab :title="this.$t('GERAL.DADOS_GERAIS')" active>
            <dados :form="form" @refs="capturarRefs" />
          </b-tab>
          <b-tab title="Contas vinculadas">
            <conta :form="form" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button
          variant="primary"
          @click="editar"
          :disabled="!form.contaId || form.contaId == 'undefined'"
        >
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import PeriodoFinanceiroService from '@/common/services/periodo-financeiro/periodo-financeiro.service';

// Components:
import Modal from '@/components/modal/Modal';
import Dados from './Dados';
import Conta from './Conta';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    Conta,
    Dados,
  },
  data() {
    return {
      refs: [],
    };
  },
  methods: {
    // FUNÇÕES CRUD:
    editar() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.editar(this.form)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_EDITADO')
          );
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.warning(this.$t('GERAL.AVISO'), err.response.data.errors);
        })
        .finally(() => {
          this.atualizarTabela();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DE VALIDAÇÃO FORMULÁRIO:
    capturarRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      if (this.form.dataInicio > this.form.dataFim) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('GERAL.DATA_INICIO_MENOR')
        );
        return;
      }
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'editarPeriodoFinanceiro');
    },
    // FUNÇÕES P/ COMPONENTE PAI:
    atualizarTabela() {
      this.$emit('atualiza-tabela');
    },
  },
};
</script>
