<template>
  <div>
    <b-row class="align-items-end">
      <b-col md="auto" class="botao-acao-filtro my-3">
        <p>Selecione uma conta:</p>
        <conta-filtro :selecionado="selecionado" @abrir-modal="openModal" />
      </b-col>
      <b-col md="6">
        <p>Selecione um período:</p>
        <periodo-filtro :selecionado="selecionado" />
      </b-col>
      <b-col md="auto">
        <b-button
          class="botao-acao-filtro w-100 my-3"
          variant="primary"
          @click="filtrar"
        >
          {{ $t('GERAL.FILTRAR') }}
        </b-button>
      </b-col>
      <b-col md="auto">
        <b-button
          class="botao-acao-filtro w-100 my-3"
          variant="secondary"
          @click="limparFiltro"
        >
          {{ $t('GERAL.LIMPAR') }}
        </b-button>
      </b-col>
      <b-col md="auto">
        <b-button
          class="botao-acao-filtro w-100 my-3"
          variant="info"
          v-if="selecionado.conta?.id"
          @click="abreModalSelecionaAno"
        >
          {{ $t('GERAL.GERAR_PERIODOS_FINANCEIROS') }}
        </b-button>
      </b-col>
      <modal-conta
        :exibir="modais.conta"
        @selecionado="exibirSelecionado"
        @fechar="closeModal('conta')"
      />
      <ModalSelecionaAno
        :exibir="modais.selecionaAno"
        @fechar="fechaModalSelecionarAno"
        @envia-ano-gerar-periodo-financeiro="enviaAnoParagerarPeriodoFinanceiro"
      />
    </b-row>
  </div>
</template>
<script>
// Components:
import ContaFiltro from './Conta';
import PeriodoFiltro from './Periodo';
import ModalConta from './modais/conta/Index';
import ModalSelecionaAno from './modais/seleciona-ano/ModalSelecionaAno';
import PeriodoFinanceiroService from '@/common/services/periodo-financeiro/periodo-financeiro.service.js';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

export default {
  components: {
    ContaFiltro,
    PeriodoFiltro,
    ModalConta,
    ModalSelecionaAno,
  },
  data() {
    return {
      modais: {
        conta: false,
        contaDados: {},
        selecionaAno: false,
      },
      selecionado: {
        contaId: [],
        dataInicio: '',
        dataFim: '',
        conta: {},
      },
    };
  },
  watch: {
    selecionado: {
      handler(value) {
        this.$emit('atualizar-filtro', value);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    enviaAnoParagerarPeriodoFinanceiro(anoLetivoId) {
      this.gerarPeriodoFinanceiro(anoLetivoId);
    },
    gerarPeriodoFinanceiro(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.gerarPeriodoFinanceiroPorAnoEConta({
        ano: anoLetivoId,
        contaId: this.selecionado.conta?.id,
      })
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_FECHADO')
          );
        })
        .catch((err) => {
          mensagem.warning(
            this.$t('GERAL.AVISO'),
            err.response.data.errors[0].mensagem
          );
        })
        .finally(() => {
          this.$emit('atualizar-tabela');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    abreModalSelecionaAno() {
      this.modais.selecionaAno = true;
    },
    fechaModalSelecionarAno() {
      this.modais.selecionaAno = false;
    },
    filtrar() {
      this.$emit('filtrar', this.selecionado);
    },
    limparFiltro() {
      this.selecionado = {
        conta: null,
        periodo: null,
      };
      this.$emit('limpar-filtro');
    },
    exibirSelecionado(key, item) {
      this.selecionado[key] = item;
    },
    openModal(key) {
      this.modais[key] = true;
    },
    closeModal(key) {
      this.modais[key] = false;
    },
  },
};
</script>
