<template>
  <modal
    id="modalPeriodoFinanceiro"
    :exibir="exibir"
    :titulo="titulo"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <div>
      <b-card no-body>
        <b-tabs card>
          <b-tab :title="this.$t('GERAL.DADOS_GERAIS')" active>
            <dados :form="form" @refs="capturarRefs" />
          </b-tab>
          <b-tab title="Contas vinculadas">
            <conta :form="form" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="criar">
          {{ $t('GERAL.SALVAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import PeriodoFinanceiroService from '@/common/services/periodo-financeiro/periodo-financeiro.service';

// Components:
import Modal from '@/components/modal/Modal';
import Dados from './Dados';
import Conta from './Conta';

export default {
  props: {
    exibir: { type: Boolean, default: false },
    form: { type: Object, default: Object },
  },
  components: {
    Modal,
    Conta,
    Dados,
  },
  data() {
    return {
      refs: [],
    };
  },
  computed: {
    titulo() {
      return this.form.id
        ? this.$t('GERAL.EDITAR')
        : this.$t('GERAL.CADASTRAR');
    },
  },
  methods: {
    // FUNÇÕES CRUD:
    criar() {
      if (!this.validarFormulario()) return;
      const formularioFormatado = {
        ...this.form,
        contasIds:
          this.form.contasIds?.length > 0
            ? this.form?.contasIds?.map((item) => item.id)
            : null,
      };
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.criar(formularioFormatado)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_CRIADO')
          );
          this.fecharModal();
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.atualizarTabela();
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DE VALIDAÇÃO FORMULÁRIO:
    capturarRefs(refs) {
      this.refs = refs;
    },
    validarFormulario() {
      if (this.form.dataInicio > this.form.dataFim) {
        mensagem.erro(
          this.$t('GERAL.ERRO'),
          this.$t('GERAL.DATA_INICIO_MENOR')
        );
        return;
      }
      return helpers.validarFormulario(this.refs);
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar', 'criarPeriodoFinanceiroLote');
    },
    // FUNÇÕES P/ COMPONENTE PAI:
    atualizarTabela() {
      this.$emit('atualiza-tabela');
    },
  },
};
</script>
