<template>
  <modal
    id="modalConta"
    :exibir="exibir"
    :titulo="'Selecionar conta'"
    :campos="$refs"
    tamanho="xl"
    @fechar="fecharModal"
  >
    <b-table
      :items="tabela.items"
      :fields="tabela.fields"
      :empty-text="$t('GERAL.NENHUM_REGISTRO')"
      head-variant="light"
      stacked="md"
      responsive
      show-empty
      striped
      hover
    >
      <template #cell(status)="item">
        <b-badge :variant="item.item.status == 'Ativo' ? 'primary' : 'warning'">
          {{ item.item.status }}
        </b-badge>
      </template>
      <template #cell(acoes)="item">
        <b-button variant="primary" @click="selecionar(item.item)">
          {{ $t('FINANCEIRO.SELECIONAR_CONTA') }}
        </b-button>
      </template>
    </b-table>

    <paginacao
      v-model="paginacao.paginacao"
      :total="paginacao.total"
      @input="getContas"
    />

    <template #modal-footer>
      <b-button variant="secondary" @click="fecharModal">
        {{ $t('GERAL.FECHAR') }}
      </b-button>
    </template>
  </modal>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import ContaService from '@/common/services/conta/conta.service';

// Components:
import Modal from '@/components/modal/Modal';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  props: {
    exibir: { type: Boolean, default: false },
  },
  components: {
    Modal,
    Paginacao,
  },
  data() {
    return {
      tabela: {
        items: [],
        fields: [
          {
            key: 'banco',
            label: this.$t('GERAL.BANCO'),
            sortable: true,
          },
          {
            key: 'agencia',
            label: this.$t('GERAL.AGENCIA'),
            sortable: true,
          },
          {
            key: 'numeroConta',
            label: this.$t('GERAL.NUMERO_CONTA'),
            sortable: true,
          },
          {
            key: 'descricao',
            label: this.$t('GERAL.DESCRICAO'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$t('GERAL.STATUS'),
            sortable: true,
          },
          {
            key: 'acoes',
            label: this.$t('GERAL.ACOES'),
          },
        ],
      },
      paginacao: {
        paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 5 },
        total: 0,
      },
    };
  },
  mounted() {
    this.getContas();
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodas(this.paginacao)
        .then(({ data }) => {
          this.tabela.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES MODAL:
    fecharModal() {
      this.$emit('fechar');
    },
    // FUNÇÕES EMISSÃO DE DADOS:
    selecionar(item) {
      this.fecharModal();
      this.$emit('selecionado', 'conta', item);
    },
  },
};
</script>
