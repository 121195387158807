<template>
  <div class="row">
    <!-- TÍTULO E DESCRIÇÃO PARA USER -->
    <div class="d-flex justify-content-between w-100">
      <b-col md="auto">
        <h4>{{ $t('FINANCEIRO.CONTA_VINCULADA') }}</h4>
      </b-col>
      <b-col md="auto">
        <b-button
          :variant="btnSelecionarContaCor"
          class="w-100 mb-2"
          @click="mostrarEsconderTabela"
        >
          {{ btnSelecionarContaTexto }}
        </b-button>
      </b-col>
    </div>

    <!-- DADOS DA CONTA SELECIONADA -->
    <div v-if="!tabela.exibir" class="col-12">
      <ul class="list-group mb-4">
        <li class="list-group-item">
          <b>Banco:</b> {{ conta.banco }} - {{ conta.bancoDescricao }}
        </li>
        <li class="list-group-item"><b>Agência:</b> {{ conta.agencia }}</li>
        <li class="list-group-item">
          <b>Número da Conta:</b> {{ conta.numeroConta }}
        </li>
        <li class="list-group-item"><b>Descrição:</b> {{ conta.descricao }}</li>
        <li class="list-group-item"><b>Status:</b> {{ conta.status }}</li>
      </ul>
    </div>

    <!-- BOTÃO DE AÇÃO -->

    <!-- TABELA DE RESULTADOS -->
    <div v-if="tabela.exibir" class="col-12">
      <b-table
        v-if="tabela.exibir"
        :items="tabela.items"
        :fields="tabela.fields"
        :empty-text="$t('GERAL.NENHUM_REGISTRO')"
        show-empty
        responsive
        stacked="md"
        selectable
        striped
        hover
      >
        <template #cell(status)="item">
          <b-badge
            :variant="item.item.status == 'Ativo' ? 'primary' : 'warning'"
          >
            {{ item.item.status }}
          </b-badge>
        </template>
        <template #cell(acoes)="item">
          <b-button variant="primary" @click="vincularConta(item.item)">
            {{ $t('FINANCEIRO.SELECIONAR_CONTA') }}
          </b-button>
        </template>
      </b-table>

      <paginacao
        v-model="paginacao.paginacao"
        :total="paginacao.total"
        @input="getContas"
      />
    </div>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import ContaService from '@/common/services/conta/conta.service';

// Components:
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    Paginacao,
  },
  data() {
    return {
      conta: {},
      btns: {
        conta: {
          cor: 'primary',
          texto: 'Selecionar conta',
        },
      },
      tabela: {
        exibir: false,
        items: [],
        fields: [
          {
            key: 'banco',
            label: this.$t('GERAL.BANCO'),
            sortable: true,
          },
          {
            key: 'agencia',
            label: this.$t('GERAL.AGENCIA'),
            sortable: true,
          },
          {
            key: 'numeroConta',
            label: this.$t('GERAL.NUMERO_CONTA'),
            sortable: true,
          },
          {
            key: 'descricao',
            label: this.$t('GERAL.DESCRICAO'),
            sortable: true,
          },
          {
            key: 'status',
            label: this.$t('GERAL.STATUS'),
            sortable: true,
          },
          {
            key: 'acoes',
            label: this.$t('GERAL.ACOES'),
          },
        ],
      },
      paginacao: {
        paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 5 },
        total: 0,
      },
    };
  },
  mounted() {
    this.getContas();
  },
  computed: {
    btnSelecionarContaCor() {
      return this.btns.conta.cor;
    },
    btnSelecionarContaTexto() {
      return this.btns.conta.texto;
    },
  },
  methods: {
    // FUNÇÕES OBTENÇÃO DE DADOS:
    getContas() {
      this.$store.dispatch(START_LOADING);
      ContaService.obterTodas(this.paginacao.paginacao)
        .then(({ data }) => {
          this.tabela.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
          // buscando o item com o mesmo ID do form para exibir os dados ao user:
          this.tabela.items.forEach((element) => {
            if (element.id == this.form.contaId) {
              this.conta = element;
            }
          });
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    vincularConta(item) {
      // salvando o id no form para enviar ao back-end:
      this.form.contaId = item.id;

      // salvando demais dados para exibir no front:
      this.conta = item;

      // alterando estilo do botão:
      this.btns.conta.cor = 'danger';
      this.btns.conta.texto = 'Alterar conta';

      // escondendo a tabela:
      this.tabela.exibir = false;
    },
    mostrarEsconderTabela() {
      this.tabela.exibir == false
        ? (this.tabela.exibir = true)
        : (this.tabela.exibir = false);
    },
  },
};
</script>
