<template>
  <div>
    <b-button
      class="w-100"
      :variant="btnSelecionarContaCor"
      @click="openModal('conta')"
    >
      {{ btnSelecionarContaTexto }}
    </b-button>
    <ul style="max-width: 600px" class="list-group" v-if="selecionado.conta?.id">
      <li class="list-group-item">
        <b>Banco:</b> {{ selecionado.conta.banco }} -
        {{ selecionado.conta.bancoDescricao }}
      </li>
      <li class="list-group-item">
        <b>Agência:</b> {{ selecionado.conta.agencia }}
      </li>
      <li class="list-group-item">
        <b>Número da Conta:</b> {{ selecionado.conta.numeroConta }}
      </li>
      <li class="list-group-item">
        <b>Descrição:</b> {{ selecionado.conta.descricao }}
      </li>
      <li class="list-group-item">
        <b>Status:</b> {{ selecionado.conta.status }}
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    selecionado: { type: Object, default: Object },
  },
  data() {
    return {
      btns: {
        conta: {
          cor: 'primary',
          texto: this.$t('FINANCEIRO.SELECIONAR_CONTA'),
        },
      },
    };
  },
  watch: {
    selecionado: {
      handler() {
        return this.selecionado.conta?.id
          ? this.exibir('conta')
          : this.limpar('conta');
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // FUNÇÕES ESTILIZAÇÃO DE BOTÕES:
    btnSelecionarContaCor() {
      return this.btns.conta.cor;
    },
    btnSelecionarContaTexto() {
      return this.btns.conta.texto;
    },
  },
  methods: {
    // FUNÇÕES ESTILIZAÇÃO DE BOTÕES:
    limpar(key) {
      this.btns[key].cor = 'primary';
      this.btns[key].texto = this.$t(
        `FINANCEIRO.SELECIONAR_${key.toUpperCase()}`
      );
    },
    exibir(key) {
      this.btns[key].cor = 'danger';
      this.btns[key].texto = this.$t(`FINANCEIRO.ALTERAR_${key.toUpperCase()}`);
    },
    // FUNÇÕES DE EMISSÃO DE DADOS AO COMPONENTE PAI:
    openModal(key) {
      this.$emit('abrir-modal', key);
    },
  },
};
</script>
