<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <input-date-only-btn
        ref="dataInicio"
        v-model="selecionado.dataInicio"
        :label="$t('FINANCEIRO.DATA_INICIO')"
      />
    </div>
    <div class="col-12 col-md-6">
      <input-date-only-btn
        ref="dataFim"
        v-model="selecionado.dataFim"
        :label="$t('FINANCEIRO.DATA_FIM')"      
      />
    </div>
  </div>
</template>
<script>
import { InputDateOnlyBtn } from '@/components/inputs';

export default {
  props: {
    selecionado: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
  },
  watch: {
    selecionado: {
      handler(newValue) {
        this.$emit('selecionado', newValue);
      },
      deep: true,
    },
  },
  methods: {
    openModal(key) {
      this.$emit('abrir-modal', key);
    },
  },
};
</script>
