<template>
  <div>
    <titulo-periodo-financeiro @cadastrar="openModal" />

    <filtro-periodo-financeiro
      @atualizar-filtro="atualizarFiltro"
      @filtrar="filtrar"
      @limpar-filtro="limpar"
    />

    <tabela-periodo-financeiro
      :items="itens"
      @editar="openModal"
      @atualizar-tabela="filtrar"
      @fechar="fechar"
      @reabertura="reabertura"
    />

    <paginacao v-model="paginacao" :total="paginacao.total" @input="filtrar" />

    <modal-editar-periodo-financeiro
      :exibir="modais.editarPeriodoFinanceiro"
      :form="modais.editarPeriodoFinanceiroDados"
      @atualiza-tabela="filtrar"
      @fechar="closeModal"
    />

    <modal-criar-periodo-financeiro-lote
      :exibir="modais.criarPeriodoFinanceiroLote"
      :form="modais.criarPeriodoFinanceiroLoteDados"
      @atualiza-tabela="filtrar"
      @fechar="closeModal"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import PeriodoFinanceiroService from '@/common/services/periodo-financeiro/periodo-financeiro.service.js';
import modais from '@/common/utils/modais';

// Components:
import TituloPeriodoFinanceiro from './components/periodo-financeiro/Titulo';
import FiltroPeriodoFinanceiro from './components/periodo-financeiro/filtro/Index';
import TabelaPeriodoFinanceiro from './components/periodo-financeiro/Tabela';
import ModalEditarPeriodoFinanceiro from '@/views/financeiro/caixa/components/periodo-financeiro/modais/modal-editar-periodo-financeiro/Index';
import ModalCriarPeriodoFinanceiroLote from '@/views/financeiro/caixa/components/periodo-financeiro/modais/modal-criar-periodo-financeiro-por-lote/Index';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  components: {
    TituloPeriodoFinanceiro,
    FiltroPeriodoFinanceiro,
    TabelaPeriodoFinanceiro,
    ModalEditarPeriodoFinanceiro,
    ModalCriarPeriodoFinanceiroLote,
    Paginacao,
  },
  data() {
    return {
      itens: [],
      modais: {
        editarPeriodoFinanceiro: false,
        editarPeriodoFinanceiroDados: {},
        criarPeriodoFinanceiroLote: false,
        criarPeriodoFinanceiroLoteDados: {},
      },
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 5,
        total: 0,
      },
      filtro: {
        contaId: [],
        dataInicio: '',
        dataFim: '',
        conta: {},
      },
    };
  },
  methods: {
    fechar(item) {
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.fechamento({
        periodoId: item.id,
        contaId: item.contaId,
      })
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_FECHADO')
          );
        })
        .catch((err) => {
          mensagem.warning(this.$t('GERAL.AVISO'), err.response.data.errors[0].mensagem);
        })
        .finally(() => {
          this.$emit('atualizar-tabela');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    reabertura(item) {
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.reabertura( item.id)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_REABERTURA')
          );
        })
        .catch((err) => {
          mensagem.warning(this.$t('GERAL.AVISO'), err.response.data.errors);
        })
        .finally(() => {
          this.$emit('atualizar-tabela');
          this.$store.dispatch(STOP_LOADING);
        });
    },
    // FUNÇÕES DO FILTRO:
    atualizarFiltro(filtro) {
      this.filtro = filtro;
    },
    filtrar() {
      let filtroFormatado = {
        contaId: this.filtro.conta ? this.filtro.conta.id : '',
        dataInicio: this.filtro.dataInicio,
        dataFim: this.filtro.dataFim,
      };
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.obterPorFiltro({
        ...filtroFormatado,
        ...this.paginacao,
      })
        .then(({ data }) => {
          this.itens = data.itens;
          this.paginacao = data.paginacao;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    limpar() {
      this.itens = [];
      this.paginacao.total = 0;
    },
    // FUNÇÕES DO MODAL:
    openModal(key, item) {
      return modais.abrirModal(this, key, item);
    },
    closeModal(key) {
      return modais.fecharModal(this, key);
    },
  },
};
</script>
