<template>
  <modal
    id="modalSelecionaAno"
    :exibir="exibir"
    :titulo="'Gerar Período Financeiro'"
    :campos="$refs"
    tamanho="sm"
    @fechar="fecharModal"
  >
    <b-row>
      <b-col cols="12">
        <input-select
          ref="anoLetivoId"
          :options="opcoes.anosLetivos"
          v-model="anoLetivoId"
          :label="$t('GERAL.ANO_LETIVO')"
          valueField="id"
          textField="ano"
          required
        />
      </b-col>
    </b-row>

    <template #modal-footer>
      <div class="d-flex justify-content-between w-100">
        <b-button variant="secondary" @click="fecharModal">
          {{ $t('GERAL.FECHAR') }}
        </b-button>
        <b-button variant="primary" @click="enviaAnoParagerarPeriodoFinanceiro">
          {{ $t('ACADEMICO.GERAR') }}
        </b-button>
      </div>
    </template>
  </modal>
</template>
<script>
import Modal from '@/components/modal/Modal';
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import { InputSelect } from '@/components/inputs';
import helpers from '@/common/utils/helpers';

export default {
  props: {
    exibir: { type: Boolean, default: false },
  },
  components: {
    Modal,
    InputSelect,
  },
  data() {
    return {
      anoLetivoId: null,
      opcoes: {
        anosLetivos: [],
      },
    };
  },
  mounted() {
    this.getAnosLetivo();
  },
  methods: {
    enviaAnoParagerarPeriodoFinanceiro() {
      if (!this.validarFormulario()) return;
      this.$emit('envia-ano-gerar-periodo-financeiro', this.anoLetivoId);
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
    getAnosLetivo() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar(this.paginacao)
        .then(({ data }) => {
          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    fecharModal() {
      this.$emit('fechar');
    },
  },
};
</script>
