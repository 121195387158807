<template>
  <b-table
    :fields="tabela.fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="editar(item.item)">
          {{ $t('GERAL.EDITAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="excluir(item.item.id)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="fechar(item.item)">
          {{ $t('FINANCEIRO.FINANCEIRO_FECHAMENTO') }}
        </b-dropdown-item>
        <b-dropdown-item @click="abrir(item.item)">
          {{ $t('FINANCEIRO.FINANCEIRO_ABERTURA') }}
        </b-dropdown-item>
        <b-dropdown-item @click="reabertura(item.item)">
          {{ $t('FINANCEIRO.FINANCEIRO_REABRIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';

// Services:
import PeriodoFinanceiroService from '@/common/services/periodo-financeiro/periodo-financeiro.service';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      tabela: {
        fields: [
          {
            key: 'dataMovimento',
            label: 'Data da Movimentação',
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'dataInicio',
            label: 'Data Início',
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'dataFim',
            label: 'Data Fim',
            formatter: (value) => {
              return value ? helpers.formatarDataBr(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'saldoAbertura',
            label: 'Saldo Inicial',
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'saldoFechamento',
            label: 'Saldo Final',
            formatter: (value) => {
              return value ? helpers.formatarReal(value) : '-';
            },
            sortable: true,
          },
          {
            key: 'statusFinanceiroDescricao',
            label: 'Status',
            sortable: true,
          },
          {
            key: 'acoes',
            label: 'Ações',
          },
        ],
      },
    };
  },
  methods: {
    editar(item) {
      this.$emit('editar', 'editarPeriodoFinanceiro', item);
    },
    fechar(item) {
      this.$emit('fechar', item);
    },
    abrir(item) {
      console.log(item);
    },
    reabertura(item) {
      this.$emit('reabertura', item);
    },
    excluir(itemId) {
      this.$store.dispatch(START_LOADING);
      PeriodoFinanceiroService.excluir(itemId)
        .then(() => {
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('FINANCEIRO.PERIODO_FINANCEIRO_EXCLUIDO')
          );
        })
        .catch((err) => {
          mensagem.warning(this.$t('GERAL.AVISO'), err.response.data.errors);
        })
        .finally(() => {
          this.$emit('atualizar-tabela');
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
