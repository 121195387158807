<template>
  <div>
    <!-- status & data movimento -->
    <div class="row">
      <div class="col-12">
        <h4>{{ $t('GERAL.DADOS_GERAIS') }}</h4>
      </div>
      <div class="col-12 col-md-6">
        <input-select-search
          ref="statusFinanceiro"
          v-model="formTemp.statusFinanceiro"
          label="FINANCEIRO.STATUS"
          :options="opcoes.status"
          required
        />
      </div>
      <div class="col-12 col-md-6">
        <input-date-only-btn
          ref="dataMovimento"
          v-model="formTemp.dataMovimento"
          label="FINANCEIRO.DATA_MOVIMENTO"
          required
        />
      </div>
    </div>

    <!-- datas -->
    <div class="row">
      <div class="col-12 col-md-6">
        <input-date-only-btn
          ref="dataInicio"
          v-model="formTemp.dataInicio"
          label="FINANCEIRO.DATA_INICIO"
          required
        />
      </div>
      <div class="col-12 col-md-6">
        <input-date-only-btn
          ref="dataFim"
          v-model="formTemp.dataFim"
          label="FINANCEIRO.DATA_FIM"
          required
        />
      </div>
    </div>

    <!-- saldos -->
    <div class="row">
      <div class="col-12 col-md-6">
        <input-number
          ref="saldoInicial"
          v-model="formTemp.saldoAbertura"
          label="FINANCEIRO.SALDO_INICIAL"
          placeholder="FINANCEIRO.SALDO_INICIAL"
          decimal
        />
      </div>
      <div class="col-12 col-md-6">
        <input-number
          ref="saldoFinal"
          v-model="formTemp.saldoFechamento"
          label="FINANCEIRO.SALDO_FINAL"
          placeholder="FINANCEIRO.SALDO_FINAL"
          decimal
        />
      </div>
    </div>

    <!-- vincular contas -->
    <p v-if="!this.form.contaId">
      {{ this.$t('FINANCEIRO.PERIODO_FINANCEIRO_CONTA_VINCULADA_OBRIGATORIA') }}
    </p>
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';

// Services:
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service.js';

// Components:
import {
  InputDateOnlyBtn,
  InputSelectSearch,
  InputNumber,
} from '@/components/inputs';

export default {
  props: {
    form: { type: Object, default: Object },
  },
  components: {
    InputDateOnlyBtn,
    InputSelectSearch,
    InputNumber,
  },
  data() {
    return {
      opcoes: {
        status: [],
      },
      formTemp: {},
    };
  },
  mounted() {
    this.$emit('refs', this.$refs);
    this.formTemp = this.form;

    // obtendo dados:
    this.getStatus();
  },
  methods: {
    getStatus() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('status-financeiro')
        .then(({ data }) => {
          data = data.map((row) => ({
            ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.status = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
